import { 
  READ_ACTION, 
  SUBSCRIPTIONS_STATICS,
  KPI,
  INVOICING_RANKING,
  SPECIALIST_CONSULTANT_RANKING,
  PILARES_RANKING,
  PEFORMANCE_EVOLUTION,
  CAMPAIGN
} from "@/constants/resources";

export default [
  {
    title: "Indicadores",
    icon: "none",
    children: [
      {
        title: "Faturômetro",
        route: "kpi-list",
        icon: "ActivityIcon",
        resource: KPI,
        action: READ_ACTION,
      },
      {
        title: "Ranking compromissos",
        route: "kpi-ranking-appoiments",
        icon: "CalendarIcon",
        resource: KPI,
        action: READ_ACTION,
      },
      {
        title: "Ranking faturamento",
        route: "kpi-ranking-invoicing",
        icon: "BarChart2Icon",
        resource: INVOICING_RANKING,
        action: READ_ACTION,
      },
      {
        title: "Ranking PN especialista",
        route: "kpi-ranking-specialist",
        icon: "TrendingUpIcon",
        resource: SPECIALIST_CONSULTANT_RANKING,
        action: READ_ACTION,
      },
      {
        title: "Ranking DNA Campeão",
        route: "kpi-ranking-dna",
        icon: "AwardIcon",
        resource: SPECIALIST_CONSULTANT_RANKING,
        action: READ_ACTION,
      },
      {
        title: "Ranking pilares",
        route: "kpi-ranking-pilares",
        icon: "ArrowUpRightIcon",
        resource: PILARES_RANKING,
        action: READ_ACTION,
      },
      {
        title: "Estatísticas de assinatura",
        route: "kpi-subscriptions-statistics",
        icon: "PieChartIcon",
        resource: SUBSCRIPTIONS_STATICS,
        action: READ_ACTION,
      },
      {
        title: "Evolução de performance",
        route: "kpi-evolucao-performance",
        icon: "LoaderIcon",
        resource: PEFORMANCE_EVOLUTION,
        action: READ_ACTION,
      },
      {
        title: "Campanhas",
        route: "campanhas",
        icon: "ShoppingBagIcon",
        resource: CAMPAIGN,
        action: READ_ACTION,
      },
    ],
  },
];
